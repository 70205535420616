


































































































































import { Component, Vue } from "vue-property-decorator";
import {
  pageOrderInvoice,
  getUserInfo,
} from "@/api";
interface IOrder {
  id: string;
  orderId: string;
  buyingTime: string;
  model: string;
  factory: string;
  amount: number;
  price: string;
  total: string;
}
interface IInvoice {
  id?: string
  userId?: number
  unitName?: string
  taxCode?: string
  regAddress?: string
  regPhone?: string
  bank?: string
  account?: string
  receiverName?: string
  receiverPhone?: string
  receiverAddress?: string
  receiverMail?: string
  type?: number

}
@Component
export default class UserInfo extends Vue {
  currentPage = 1;
  pageSize = 10;
  total = 0;
  invoiceList: Array<IInvoice> = [];
 



//mobile手机，phone座机
  invoice : IInvoice = {
    id: "",
    userId: 0,
    unitName: "",
    taxCode: "",
    regAddress: "",
    regPhone:"",
    bank:"",
    account:"",
    receiverName:"",
    receiverPhone:"",
    receiverAddress:"",
  };
  userInfo = {
    isVip : 0,
    companyId : "0",
    userId : 0,
    
  };
  mounted(): void {
    // this.fetchOrderList();
    this.getUserInfo();
    // this.listUserAddress();
  }
getUserInfo(): void{
  getUserInfo({}).then(res => {
    if(res.status == 200){
        if(res.data.code == 200){
          this.userInfo = res.data.user
          window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
          this.getDataList();
        }else{
          this.$message.error(res.data.msg );
        }
      }else{
        this.$message.error(res.data.statusText);
      } 
    }).catch(() => {
      console.log(' error ')
    })
};
 
  

  //发票列表
  getDataList(): void {
   let param = {
        "currentPage": this.currentPage,
        "pageSize":this.pageSize
    }
    pageOrderInvoice(param)
      .then((res) => {
        if (res.status == 200) {
          if (res.data.code == 200) {
            // console.log(res.data.data)
            this.total = res.data.data.total;
            this.invoiceList = res.data.data.records;
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        console.log(" error ");
      });
  }



  handleSizeChange(val: number): void {
    this.pageSize = val;
    this.currentPage = 1;
    this.getDataList();
  }


  handleCurrentChange(val: number): void {
    this.currentPage = val;
    this.getDataList();
  }
}
